.paginationContainer {
  display: flex;
  justify-content: center;
  padding: 16px;
  margin-top: 20px;
}

.pagination .ant-pagination-item {
  border: 1px solid var(--primary-color);
  background-color: var(--card-color);
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .ant-pagination-options {
  display: none;
}

.pagination .ant-pagination-item a {
  color: #bbbbbb !important;
}

.pagination .ant-pagination-item:hover {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  color: var(--white-color);
}

.pagination .ant-pagination-item-active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.pagination .ant-pagination-item-active a {
  color: var(--white-color);
}

.pagination .ant-pagination-item-link {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.pagination .ant-pagination-item-link:hover {
  color: var(--white-color);
  background-color: var(--primary-color);
}

.pagination .ant-pagination-prev .ant-pagination-item-link,
.pagination .ant-pagination-next .ant-pagination-item-link {
  color: var(--primary-color);
  border-color: var(--primary-color);
  background-color: var(--card-color);
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .ant-pagination-item-ellipsis {
  color: white !important;
}

.pagination .ant-pagination-prev .ant-pagination-item-link:hover,
.pagination .ant-pagination-next .ant-pagination-item-link:hover {
  color: var(--white-color);
  background-color: var(--primary-color);
}

@media (max-width: 600px) {
  .paginationContainer {
    padding: 8px;
    margin-top: 10px;

    display: flex;
    align-items: center;
  }

  .ant-pagination-simple-pager {
    height: 38px !important;
    margin-right: 10px !important;
  }

  .ant-pagination-simple-pager input {
    border: 1px solid var(--primary-color) !important;
    background-color: var(--card-color) !important;
    width: 38px !important;
    height: 38px !important;
    color: #bbbbbb !important;
  }

  .ant-pagination-item-link {
    border: 1px solid var(--primary-color) !important;
    background-color: var(--card-color) !important;
    width: 38px !important;
    height: 38px !important;
    color: #bbbbbb !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-pagination-simple-pager {
    color: #bbbbbb !important;
  }

  .pagination .ant-pagination-item,
  .pagination .ant-pagination-prev .ant-pagination-item-link,
  .pagination .ant-pagination-next .ant-pagination-item-link {
    width: 32px;
    height: 32px;
  }

  .pagination .ant-pagination-item a {
    font-size: 12px;
  }
}
