.card {
  background-color: var(--card-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
}

.img {
  margin: 30px 0px;
  width: 200px;
  height: 120px;
}

.name {
  font-size: 22px;
  text-align: center;
  padding: 0px 20px;
}

.flex {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 15px 0px;
}

.coin-img {
  width: 30px;
  height: 30px;
}

.price {
  font-size: 18px;
  color: var(--white-color);
}

.count {
  font-size: 22px;
}

.color {
  font-size: 14px;
  color: var(--white-color);
  margin: 20px 0px;
}

.btn {
  width: 100%;
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-color);
  border-radius: 0px 0px 16px 16px;
  gap: 8px;
  margin-top: auto;
  cursor: pointer;
  user-select: none;
}

.btn-txt {
  font-size: 18px;
  color: var(--white-color);
}

.modal-product-name {
  font-size: 30px;
  color: var(--white-color);
  text-align: center;
}

.modal-text {
  font-size: 30px;
  text-align: center;
}

.modal-btn-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.modal-icon {
  font-size: 25px;
  margin-right: 4px;
}

.modal-btn {
  padding: 8px 20px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: var(--white-color);
  background: var(--primary-color);
  cursor: pointer;
}

.modal-btn:hover {
  opacity: 0.7;
}

.modal-btn:active {
  transform: scale(0.96);
}

@media (max-width: 1200px) {
  .card {
    height: auto;
  }
}

@media (max-width: 600px) {
  .modal-product-name {
    font-size: 24px;
  }

  .modal-text {
    font-size: 24px;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .modal-btn-flex {
    flex-direction: column;
  }
}
