.main-wrapper {
  width: 100%;
  background-color: var(--primary-color);
}

.container {
  max-width: 1440px;
  padding: 20px 35px;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text {
  font-size: 18px;
  color: var(--white-color);
}

@media (max-width: 1200px) {
  .text {
    font-size: 20px;
  }
  .text span {
    display: block;
  }
}

@media (max-width: 920px) {
  .container {
    padding: 20px 25px;
  }
  .text span {
    display: inline;
  }

  .text {
    text-align: center;
  }
  .container {
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 20px;
  }

  .text {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .container {
    gap: 10px;
  }
  .text {
    font-size: 15px;
  }
}
