.container {
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1440px;
  width: 100%;
  margin: auto;
}

.logo-header {
  font-size: 40px;
  color: var(--white-color);
  font-weight: 600;
}

.nav-items {
  display: flex;
  align-items: center;
  gap: 50px;
  transition: right 0.3s ease-in-out;
}

.nav-item {
  font-size: 26px;
  font-weight: 400;
  cursor: pointer;
  color: var(--white-color);
}

.nav-link {
  text-decoration: none;
}

.nav-item:hover {
  background: linear-gradient(96.52deg, #bac5ff 9.98%, #a05fff 93.94%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.default-profile-img {
  width: 50px;
  height: 50px;
}

.profile-img-wrapper {
  position: relative;
  width: 45px;
  height: 45px;
  border-radius: 8px 25px 8px 8px;
  cursor: pointer;
}

.profile-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px 0px 8px 8px;
  cursor: pointer;
  clip-path: polygon(0 0, calc(100% - 18px) 0, 100% 18px, 100% 100%, 0 100%);
}

.profile-arrow {
  position: absolute;
  top: -15px;
  right: -10px;
  font-size: 25px;
  font-weight: bold;
  color: var(--white-color);
  transform: rotate(-45deg);
}

.profile {
  cursor: pointer;
}

.router-links {
  text-decoration: none;
}

.arrow-icon {
  font-size: 20px;
}

.nav-item:hover .arrow-icon {
  color: var(--primary-color);
}

.menu-item {
  font-size: 18px !important;
  display: flex;
  align-items: center;
}

.flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon {
  margin-right: 5px;
  font-size: 20px;
}

@media (max-width: 1250px) {
  .nav-items {
    flex-direction: column;
    position: fixed;
    top: 100px;
    right: -600px;
    height: 100vh;
    padding: 40px 20px;
    background-color: var(--bg-color);
    z-index: 999;
    transition: right 0.3s ease-in-out;
  }

  .open {
    right: 0;
  }
}

@media (max-width: 900px) {
  .container {
    padding: 30px;
  }

  .nav-items {
    top: 90px;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 20px;
  }

  .language-box {
    display: none;
  }

  .nav-items {
    top: 85px;
  }
}

@media (max-width: 340px) {
  .nav-items {
    height: 400px;
    overflow-y: scroll;
  }
}
