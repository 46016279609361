.container {
  max-width: 1440px;
  margin: 0px auto;
}

.img {
  width: 750px;
  height: 800px;
}

@media (max-width: 1600px) {
  .img {
    width: 600px;
    height: 650px;
  }
}
