.container {
  width: 100%;
  padding: 80px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1440px;
  margin: auto;
}

.header {
  font-size: 35px;
  margin-bottom: 30px;
  text-align: center;
}

.levels-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-top: 40px;
}

.level-card {
  background-color: var(--card-color);
  padding: 30px;
  border-radius: 15px;
  color: var(--white-color);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease,
    background-color 0.3s ease;
  cursor: pointer;
  user-select: none;
  border: 2px solid transparent;
}

.level-card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 8px 20px rgba(141, 102, 255, 0.3);
  background-color: var(--primary-color);
  border-color: var(--white-color);
}

.level-card h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 500;
}

.level-card p {
  margin-top: 10px;
  font-size: 16px;
  opacity: 0.8;
}

.loader-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
}

@media (max-width: 1200px) {
  .header {
    font-size: 30px;
  }

  .levels-container {
    gap: 25px;
    margin-top: 30px;
  }

  .level-card {
    padding: 20px;
  }

  .level-card h2 {
    font-size: 22px;
  }
}

@media (max-width: 900px) {
  .header {
    font-size: 25px;
  }

  .container {
    padding: 40px 30px;
  }

  .levels-container {
    gap: 18px;
    margin-top: 24px;
  }

  .level-card {
    padding: 12px;
  }

  .level-card h2 {
    font-size: 20px;
  }

  .level-card:hover {
    transform: translateY(-5px);
  }
}

@media (max-width: 600px) {
  .header {
    font-size: 24px;
  }

  .container {
    padding: 20px 20px;
  }

  .levels-container {
    gap: 20px;
    flex-direction: column;
  }

  .level-card {
    padding: 12px;
  }

  .level-card h2 {
    font-size: 20px;
  }

  .level-card p {
    font-size: 14px;
  }
}
