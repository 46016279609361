.loader-container {
  width: 100%;
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  max-width: 1440px;
  width: 100%;
  margin: 40px auto 60px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 40px;
}

.header {
  font-size: 35px;
  text-align: center;
  margin-bottom: 60px;
}

.cards-wrap {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
}

.img {
  border: 3px solid white;
  border-radius: 8px;
  width: 100%;
  height: 220px;
  object-fit: cover;
}

.img iframe {
  width: 100%;
  height: 100%;
}

@media (max-width: 1200px) {
  .header {
    margin-bottom: 40px;
    width: 70%;
  }
  .cards-wrap {
    gap: 30px;
  }

  .img {
    height: 200px;
  }
}

@media (max-width: 1020px) {
  .container {
    padding: 0px 30px;
  }
  .header {
    margin-bottom: 40px;
    width: 90%;
    font-size: 30px;
    line-height: 40px;
  }
  .cards-wrap {
    gap: 30px;
    grid-template-columns: 1fr 1fr;
  }
  .img {
    height: 220px;
  }
}

@media (max-width: 780px) {
  .cards-wrap {
    gap: 20px;
  }
  .img {
    height: 200px;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 0px 20px;
  }
  .cards-wrap {
    grid-template-columns: 400px;
    justify-content: center;
    grid-template-rows: auto;
  }
  .header {
    width: 100%;
    font-size: 26px;
    line-height: 32px;
  }
  .img {
    height: 220px;
  }
}

@media (max-width: 480px) {
  .cards-wrap {
    grid-template-columns: 1fr;
  }
  .img {
    height: 200px;
  }
}
