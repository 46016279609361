.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.photo-container {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.profile-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: contain;
  margin-right: 20px;
  background-color: #1a1a1a;
}

.default-img {
  padding-top: 10px;
}

.photo-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.photo-input {
  display: none;
}

.camera-icon {
  font-size: 24px;
  color: var(--primary-color);
}

.input-container {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 20px;
  color: var(--white-color);
  margin-bottom: 20px;
}

.input {
  border: none;
  outline: none;
  background-color: #1a1a1a;
  padding: 20px 25px;
  border-radius: 10px;
  width: 450px;
  height: 65px;
  font-size: 18px;
  color: var(--white-color);
  font-weight: 600;
}

.password-input {
  padding-right: 60px !important;
}

.input:focus {
  background-color: var(--bg-color);
  border: 2px solid var(--primary-color);
  box-shadow: 0 0 5px rgba(255, 202, 29, 0.5);
}

.input::placeholder {
  color: #4d4d4d;
  font-size: 18px;
  font-weight: 600;
}

.btn {
  border: none;
  outline: none;
  border-radius: 15px;
  background-color: var(--primary-color);
  padding: 10px 18px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s all;
  width: fit-content;
}

.btn:active {
  transform: scale(0.96);
}

.line {
  background: #28282e;
  width: 450px;
  height: 1px;
  margin: 20px 0px 40px 0px;
}

.password-container {
  position: relative;
  width: fit-content;
}

.eye-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: white;
  font-size: 22px;
}

@media (max-width: 600px) {
  .container {
    width: 100%;
  }
  .input-container {
    width: 100%;
  }
  .input {
    width: 100%;
    height: 55px;
    font-size: 16px;
    padding: 10px 15px;
  }
  .line {
    width: 100%;
  }
  .btn {
    padding: 10px 15px;
    font-size: 16px;
  }
  .profile-img {
    width: 70px;
    height: 70px;
  }
  .camera-icon {
    font-size: 20px;
  }
}
