.container {
  max-width: 1440px;
  margin: 80px auto;
  display: flex;
  gap: 60px;
  padding: 0px 40px;
}

.loader-container {
  margin-top: 40px;
}

.right-box {
  width: 100%;
}

.flex {
  display: flex;
  align-items: center;
  gap: 60px;
}

.title {
  font-size: 30px;
  color: #6c6c70;
  cursor: pointer;
  transition: 0.5s all;
}

.title:hover {
  color: var(--white-color);
}

.title-active {
  font-size: 30px;
  cursor: pointer;
  background: linear-gradient(96.52deg, #bac5ff 9.98%, #a05fff 93.94%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 40px;
  margin-top: 40px;
}

@media (max-width: 1400px) {
  .container {
    margin: 60px auto;
    gap: 40px;
  }

  .card-wrapper {
    gap: 30px;
  }
}

@media (max-width: 1200px) {
  .container {
    margin: 40px auto;
  }
  .card-wrapper {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }

  .flex {
    gap: 30px;
  }

  .title {
    font-size: 25px;
  }

  .title-active {
    font-size: 25px;
  }
}

@media (max-width: 900px) {
  .container {
    flex-direction: column;
    padding: 0px 30px;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 0px 20px;
  }

  .card-wrapper {
    grid-template-columns: 300px;
    justify-content: center;
  }
  .flex {
    gap: 20px;
  }

  .title {
    font-size: 20px;
    text-align: center;
  }

  .title-active {
    font-size: 20px;
    text-align: center;
  }
}

@media (max-width: 380px) {
  .card-wrapper {
    grid-template-columns: 100%;
  }
}
