.opener-container {
  width: 100%;
  height: 100vh;
  position: relative;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

@media (max-width: 900px) {
  .opener-container {
    height: auto;
  }
}
