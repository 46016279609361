.container {
  margin: 80px auto;
  max-width: 1400px;
  width: 100%;
  display: flex;
  gap: 50px;
  padding: 0px 40px;
}

.test-box {
  flex: 3;
  border: 1px solid #1e1e1e;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.qs-box {
  padding: 15px 25px;
  border-bottom: 1px solid #1e1e1e;
  font-size: 24px;
  color: var(--white-color);
}

.option-box {
  padding: 40px 25px 0px 25px;
}

.quiz-img {
  width: 100%;
  height: 400px;
  cursor: zoom-in;
}

.flex {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0px;
}

.header {
  font-size: 40px;
  margin-bottom: 50px;
  text-align: center;
  padding: 0px 40px;
}

.option-text {
  font-size: 24px;
  color: var(--white-color);
}

.btn {
  border: none;
  outline: none;
  padding: 12px 40px;
  border-radius: 40px;
  cursor: pointer;
  background: var(--primary-color);
  color: var(--white-color);
  font-size: 24px;
  margin: 10px 25px 20px auto;
}

.right-box {
  flex: 2;
}

.btn:active {
  transform: scale(0.95);
}

.progress-box {
  background-color: var(--card-color);
  padding: 30px;
  box-shadow: -5px 5px 5px 0px #6a01fc;
}

.progress-text {
  color: var(--white-color);
  font-size: 20px;
  text-align: center;
  margin-bottom: 10px;
}

.progress-flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.progress-item {
  background: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  color: var(--white-color);
  font-size: 26px;
}

.loader-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 80px 0px;
}

.timer {
  font-size: 20px;
  color: var(--white-color);
  text-align: center;
  background-color: var(--card-color);
  padding: 10px;
  box-shadow: -5px 5px 5px 0px #6a01fc;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.clock-icon {
  font-size: 24px;
}

@media (max-width: 1200px) {
  .quiz-img {
    height: 300px;
  }
}

@media (max-width: 900px) {
  .container {
    padding: 0px 30px;
    gap: 40px;
    margin: 60px auto;
    flex-direction: column-reverse;
    max-width: 600px;
  }

  .header {
    font-size: 30px;
    text-wrap: wrap;
    padding: 0px 30px;
  }

  .progress-box,
  .timer {
    box-shadow: 0px 0px 5px 5px #6a01fc;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 0px 20px;
    gap: 30px;
    margin: 40px auto;
  }

  .header {
    font-size: 22px;
    text-wrap: wrap;
    padding: 0px 20px;
    margin-bottom: 20px;
  }

  .quiz-img {
    height: auto;
  }

  .progress-box {
    padding: 18px;
  }

  .progress-text {
    font-size: 18px;
  }

  .progress-flex {
    gap: 10px;
  }

  .progress-item {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }

  .qs-box {
    padding: 10px 15px;
    font-size: 20px;
  }

  .option-box {
    padding: 20px 15px 0px 15px;
  }

  .option-text {
    font-size: 20px;
  }

  .btn {
    padding: 8px 40px;
    font-size: 20px;
  }
}
