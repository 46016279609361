.container {
  max-width: 1440px;
  padding: 0px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: 180px;
}

.title {
  font-size: 50px;
  text-align: center;
}

.card-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 56px;
  width: 100%;
  margin: 80px 0px 50px 0px;
}

.box {
  background: var(--card-color);
  padding: 15px 20px;
  border-radius: 10px;
}

.shadow-left {
  box-shadow: 5px 5px 5px 0px #6a01fc;
}

.shadow-right {
  box-shadow: -5px 5px 5px 0px #6a01fc;
}

.subtitle {
  font-size: 30px;
  color: var(--white-color);
}

.descr {
  font-size: 18px;
  color: #bababa;
}

.imgs-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 60px;
  display: none;
}

.img {
  flex: 1;
  border-radius: 20px;
  width: 100%;
}

@media (max-width: 1200px) {
  .title {
    font-size: 40px;
  }

  .card-wrap {
    gap: 40px;
    margin: 60px 0px 40px 0px;
  }

  .box {
    padding: 12px 16px;
  }

  .subtitle {
    font-size: 25px;
  }

  .descr {
    font-size: 17px;
  }
}

@media (max-width: 900px) {
  .container {
    margin-top: 100px;
    padding: 0px 30px;
  }
  .title {
    font-size: 32px;
  }

  .card-wrap {
    gap: 30px;
    margin: 50px 0px 30px 0px;
  }

  .subtitle {
    font-size: 20px;
  }

  .descr {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .container {
    margin-top: 60px;
    padding: 0px 20px;
  }
  .title {
    font-size: 27px;
  }

  .card-wrap {
    grid-template-columns: 1fr;
    gap: 30px;
    margin: 40px 0px 20px 0px;
  }

  .shadow-right {
    box-shadow: 5px 5px 5px 0px #6a01fc;
  }
}

@media (max-width: 420px) {
  .subtitle {
    font-size: 18px;
  }

  .descr {
    font-size: 15px;
  }
}
