.container {
  margin-bottom: 8px;
}

.date {
  font-size: 20px;
  color: #646669;
  margin-bottom: 14px;
}

.box {
  background-color: var(--card-color);
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 20px;
  color: #9997a5;
  max-width: 500px;
}

.status-badge {
  padding: 3px 6px;
  border-radius: 4px;
  font-weight: bold;
  margin-bottom: 6px;
  font-size: 12px;
  width: fit-content;
  color: var(--white-color);
}

.status-error {
  background-color: #d42620;
}

.status-success {
  background-color: #4caf50;
}

@media (max-width: 600px) {
  .date {
    font-size: 18px;
  }
  .box {
    padding: 10px 15px;
    font-size: 18px;
  }
}
