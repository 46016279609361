.container {
  padding: 100px 40px 250px 40px;
  max-width: 1440px;
  margin: auto;
  position: relative;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex {
  display: flex;
  align-items: center;
  gap: 56px;
}

.text {
  font-size: 100px;
}

.bottom-flex {
  display: flex;
  align-items: center;
  gap: 56px;
  margin-top: 50px;
  margin-left: -20px;
}

.f-img {
  width: 215px;
}

.statistic-bg {
  position: absolute;
  right: 0px;
  bottom: 0px;
  user-select: none;
}

.position-txt {
  font-size: 50px;
  margin-top: 50px;
  font-weight: 400;
}

.level-txt {
  color: #bcc6cc;
  font-size: 70px;
  position: absolute;
  z-index: 999;
  right: 190px;
  bottom: 180px;
}

@media (max-width: 1450px) {
  .flex {
    gap: 40px;
  }
  .bottom-flex {
    gap: 40px;
  }
  .f-img {
    width: 180px;
  }
  .xp-img {
    width: 260px;
  }
  .text {
    font-size: 80px;
  }
  .level-img {
    width: 210px;
  }
  .statistic-bg {
    z-index: -1;
  }
}

@media (max-width: 1250px) {
  .flex {
    gap: 10px;
  }
  .bottom-flex {
    gap: 10px;
  }
  .f-img {
    width: 150px;
  }
  .xp-img {
    width: 230px;
  }
  .text {
    font-size: 70px;
  }
  .level-img {
    width: 180px;
  }
  .position-txt {
    font-size: 45px;
    width: 60%;
  }
  .level-txt {
    right: 210px;
    bottom: 200px;
    font-size: 60px;
  }
}

@media (max-width: 1020px) {
  .f-img {
    width: 130px;
  }
  .xp-img {
    width: 210px;
  }
  .text {
    font-size: 60px;
  }
  .level-img {
    width: 160px;
  }
  .position-txt {
    font-size: 40px;
    width: 70%;
  }

  .statistic-bg {
    transform: scale(0.8);
  }
  .level-txt {
    right: 170px;
    bottom: 200px;
    font-size: 60px;
  }
}

@media (max-width: 900px) {
  .container {
    padding: 80px 30px 250px 30px;
  }
  .wrapper {
    flex-direction: column;
    gap: 50px;
  }

  .bottom-flex {
    width: fit-content;
    margin: auto;
    margin-top: 20px;
    justify-content: center;
  }

  .text {
    font-size: 70px;
  }

  .level-text {
    font-size: 60px;
    line-height: 60px;
  }
  .level-text span {
    display: block;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 60px 20px;
  }
  .wrapper {
    gap: 40px;
  }
  .text {
    font-size: 60px;
  }
  .f-img {
    width: 110px;
  }
  .xp-img {
    width: 150px;
  }
  .level-img {
    width: 130px;
  }
  .bottom-flex {
    flex-direction: column;
  }
  .level-text {
    text-align: center;
    background: none;
    color: white !important;
    -webkit-text-fill-color: white;
  }

  .level-text span {
    display: none;
  }

  .position-txt,
  .level-txt,
  .statistic-bg {
    display: none;
  }
}

@media (max-width: 480px) {
  .wrapper {
    gap: 30px;
  }
  .bottom-flex {
    margin-top: 10px;
  }
  .f-img {
    width: 90px;
  }
  .xp-img {
    width: 130px;
  }
  .level-img {
    width: 110px;
  }
  .text {
    font-size: 50px;
  }
}
