.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.icon {
  font-size: 100px;
  color: var(--primary-color);
}

.title {
  font-size: 28px;
  color: var(--primary-color);
  text-align: center;
}

@media (max-width: 900px) {
  .icon {
    font-size: 80px;
  }

  .title {
    font-size: 24px;
  }
}

@media (max-width: 900px) {
  .icon {
    font-size: 60px;
  }

  .title {
    font-size: 20px;
  }
}
