.main-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container {
  max-width: 1440px;
  margin: 100px auto 0px auto;
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 40px;
}

.text-box h1 {
  font-size: 40px;
  color: #ccc9d0;
  text-align: center;
  font-weight: 400;
}

.flex {
  display: flex;
  align-items: center;
  gap: 125px;
  margin: auto;
  margin-top: 25px;
  margin-left: 150px;
}

.descr {
  font-size: 25px;
  color: #ccc9d0;
}

.result-text {
  color: var(--primary-color);
  font-size: 30px;
}

.settings-flex {
  display: flex;
  align-items: center;
  gap: 75px;
  width: fit-content;
  margin: 72px auto 0px auto;
}

.bottom-box {
  margin-top: auto;
}

.icon {
  cursor: pointer;
  user-select: none;
  margin-bottom: 50px;
}

.wpm-box {
  display: none;
}

@media (max-width: 1400px) {
  .container {
    padding: 0px 40px;
  }
  .img {
    width: 80%;
  }
}

@media (max-width: 1200px) {
  .text-box h1 {
    font-size: 30px;
  }

  .descr {
    font-size: 20px;
  }

  .result-text {
    font-size: 25px;
  }
}

@media (max-width: 900px) {
  .container {
    margin-top: 50px;
    padding: 0px 30px;
  }
  .img {
    width: 85%;
  }
  .wrapper {
    gap: 20px;
  }
  .img {
    height: 180px;
  }
  .text-box h1 {
    font-size: 25px;
  }
  .flex {
    margin-top: 10px;
    margin-left: 100px;
    gap: 50px;
  }
  .descr {
    font-size: 18px;
  }

  .result-text {
    font-size: 20px;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 0px 20px;
  }
  .wrapper {
    display: none;
  }

  .wpm-box {
    display: inline;
  }

  .flex {
    margin-left: 0px;
    background: var(--card-color);
    padding: 20px;
    border-radius: 10px;
  }

  .settings-flex {
    margin-top: 40px;
  }

  .icon {
    width: 30px;
  }
}

@media (max-width: 380px) {
  .container {
    width: 100%;
  }
  .flex {
    width: 100%;
    gap: 0px;
    justify-content: space-between;
  }
  .result-text {
    text-wrap: nowrap;
  }
}
