.header {
  font-size: 35px;
  padding-bottom: 30px;
  text-align: center;
  margin-top: 80px;
}

.categories-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: auto;
  margin-top: 40px;
  max-width: 1440px;
  padding: 0px 40px;
  background-color: red;
  background-color: var(--bg-color);
}

.category-card {
  background: linear-gradient(145deg, #a384ff, var(--primary-color));
  border-radius: 15px;
  width: 250px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.category-card:hover {
  transform: scale(1.05);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  background: linear-gradient(145deg, var(--primary-color), #a384ff);
}

.category-icon {
  position: absolute;
  font-size: 100px;
  opacity: 0.2;
  z-index: 1;
  color: var(--white-color);
}

.category-content {
  position: relative;
  z-index: 2;
  color: var(--white-color);
}

.category-name {
  font-size: 22px;
  font-weight: bold;
  margin: 0;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-color);
  margin-top: 100px;
}

@media (max-width: 1200px) {
  .header {
    font-size: 30px;
  }
}

@media (max-width: 900px) {
  .header {
    font-size: 25px;
    padding: 0px 30px;
    margin-top: 60px;
    margin-bottom: 25px;
  }

  .categories-container {
    padding: 0px 30px;
  }
}

@media (max-width: 600px) {
  .header {
    font-size: 24px;
    padding: 0px 20px;
    margin-bottom: 20px;
    margin-top: 40px;
  }

  .categories-container {
    padding: 0px 20px;
    padding-bottom: 60px;
    flex-direction: column;
    align-items: center;
  }
}
