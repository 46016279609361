.container {
  height: 600px;
  width: 450px;
  background-color: var(--card-color);
  padding: 30px 40px 30px 40px;
  position: sticky;
  top: 20px;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 40px;
  color: var(--white-color);
  text-wrap: nowrap;
  font-weight: 600;
  margin-bottom: 20px;
}

.line {
  position: absolute;
  top: -10px;
  right: -30px;
  width: 200px;
  height: 6px;
  background: var(--bg-color);
  transform: rotate(40deg);
}

.item {
  width: 100%;
  padding: 8px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 5px 0px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.item:hover {
  transform: scale(1.05);
}

.active {
  background-color: #333333;
}

.active .text {
  color: var(--white-color);
}

.img {
  width: 25px;
  height: 25px;
  transition: transform 0.3s ease;
  color: #646669;
}

.text {
  font-size: 20px;
  color: #ebe7ff;
  transition: color 0.3s ease;
}

.active .img {
  transform: rotate(360deg);
}

@media (max-width: 1400px) {
  .container {
    width: 400px;
    padding: 25px 35px;
  }
  .title {
    text-wrap: wrap;
    font-size: 35px;
  }
}

@media (max-width: 1020px) {
  .container {
    position: static;
    height: 100%;
    width: 100%;
    border-radius: 8px;
  }

  .item:hover {
    transform: scale(1.02);
  }

  .line {
    display: none;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 20px 25px;
  }
  .title {
    font-size: 30px;
  }
}
