.set-lang-title {
  font-size: 24px;
  color: var(--white-color);
  margin-bottom: 25px;
}

.set-lang-languageIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.set-lang-container .ant-radio-wrapper {
  display: flex !important;
  align-items: center !important;
}

.set-lang-container .ant-radio-wrapper span {
  display: flex;
  align-items: center;
}

.set-lang-text {
  font-size: 20px;
  color: var(--white-color);
}

.set-lang-container .ant-radio-group {
  display: flex !important;
  flex-direction: column !important;
  gap: 20px !important;
}

/* New style to set active text color */
.set-lang-container .ant-radio-wrapper-checked .set-lang-text {
  color: var(--primary-color);
}

@media (max-width: 600px) {
  .set-lang-container {
    width: 100%;
  }
}
