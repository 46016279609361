.main-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  width: 100%;
  background-image: url(../../shared/assets/images/multiply-bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 40px;
}

.system-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 70px;
  border-radius: 10px;
  background-color: var(--card-color);
  width: fit-content;
  margin-bottom: 20px;
}

.system-item-flex {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}

.system-text {
  font-size: 25px;
  color: #ccc9d0;
}

.active {
  color: var(--primary-color);
}

.line {
  background-color: #ccc9d0;
  height: 100%;
  width: 5px;
  border-radius: 10px;
  margin: 0px 50px;
}

.circle {
  height: 420px;
  width: 420px;
  border-radius: 50%;
  background-image: url(../../shared/assets/images/multiply-ball.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 50px;
}

.output {
  font-size: 40px;
  color: #6dafd2;
}

.input {
  border: none;
  outline: none;
  background: #ffffff0f;
  padding: 15px 25px;
  border-radius: 60px;
  width: 70%;

  /* txt */
  font-size: 25px;
  color: var(--white-color);
  appearance: none;
  -moz-appearance: textfield;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.timer {
  text-align: center;
  font-size: 60px;
  color: var(--white-color);
  margin-bottom: 40px;
  font-weight: bold;
}

.bottom-container {
  margin-top: auto;
}

@media (max-width: 1400px) {
  .circle {
    height: 380px;
    width: 380px;
    margin-bottom: 35px;
  }
}

@media (max-width: 900px) {
  .container {
    padding: 0px 30px;
  }

  .input {
    padding: 10px 18px;
    font-size: 20px;
  }

  .system-box {
    padding: 10x 40px;
    margin-bottom: 30px;
  }

  .system-text {
    font-size: 20px;
    text-align: center;
  }

  .circle {
    height: 340px;
    width: 340px;
    margin-bottom: 35px;
  }

  .line {
    margin: 0px 35px;
  }

  .timer {
    font-size: 45px;
    margin-bottom: 25px;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 0px 20px;
  }

  .circle {
    height: 300px;
    width: 300px;
    margin-bottom: 35px;
  }

  .output {
    font-size: 32px;
  }

  .system-box {
    padding: 8px 20px;
  }

  .system-text {
    font-size: 16px;
    text-align: center;
  }

  .timer {
    font-size: 35px;
    margin-bottom: 15px;
  }

  .line {
    margin: 0px 25px;
  }
}

@media (max-width: 380px) {
  .circle {
    height: 270px;
    width: 270px;
    margin-bottom: 35px;
  }
}
