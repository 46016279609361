.container {
  height: 600px;
  background-color: var(--card-color);
  padding: 30px 40px;
  position: sticky;
  top: 20px;
  min-width: 440px;
}

.title {
  font-size: 40px;
  color: var(--white-color);
  text-wrap: nowrap;
}

.flex {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.coin-img {
  width: 80px;
  height: 80px;
}

.coin-text {
  font-size: 35px;
}

@media (max-width: 1400px) {
  .container {
    width: 400px;
    padding: 25px 35px;
  }
  .title {
    text-wrap: wrap;
    font-size: 35px;
  }
}

@media (max-width: 1100px) {
  .container {
    min-width: auto;
  }
}

@media (max-width: 900px) {
  .container {
    position: static;
    height: 100%;
    width: 100%;
    border-radius: 8px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 20px 25px;
  }
  .title {
    font-size: 30px;
  }
  .flex {
    gap: 10px;
  }
  .coin-img {
    width: 60px;
    height: 60px;
  }
}
