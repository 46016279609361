/* FAQ.module.css */

.faq-title {
  font-size: 24px;
  color: var(--white-color);
  margin-bottom: 25px;
}

.accordion {
  width: 600px;
  border: none;
  border-radius: 10px;
}

.accordion .ant-collapse-header {
  font-size: 20px;
  color: var(--white-color) !important;
  background-color: #13131b;
  border: none !important;
  display: flex;
  align-items: center !important;
}

.accordion .ant-collapse-header .ant-collapse-expand-icon svg {
  font-size: 20px !important;
}

.accordion .ant-collapse-content {
  background-color: var(--card-color);
  border: none !important;
}

.panel {
  border-bottom: 1px solid var(--primary-color) !important;
}

.panel:last-child {
  border-bottom: none !important;
}

.panel-content {
  color: var(--white-color) !important;
  font-size: 18px !important;
  margin: 8px 0 !important;
}

.panel-content p {
  margin: 0 !important;
}

@media (max-width: 780px) {
  .accordion {
    width: 90vw;
  }

  .accordion .ant-collapse-header {
    font-size: 18px;
  }

  .accordion .ant-collapse-header .ant-collapse-expand-icon svg {
    font-size: 18px !important;
  }

  .panel-content {
    font-size: 16px !important;
  }
}

@media (max-width: 480px) {
  .faq-loader-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .faq-title {
    text-align: center;
  }
  .accordion {
    width: 100%;
  }
  .accordion .ant-collapse-header {
    font-size: 16px;
  }
  .accordion .ant-collapse-header .ant-collapse-expand-icon svg {
    font-size: 16px !important;
  }

  .ant-collapse-content-box {
    padding: 12px !important;
  }

  .panel-content {
    font-size: 15px !important;
    margin: 0px !important;
  }
}
