.posibilities-swiper-container {
  width: 70%;
  margin: 100px auto;
  position: relative;
}

.posibilities-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.posibilities-card-img {
  width: 85%;
  object-fit: cover;
}

.posibilities-card-title {
  margin-top: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 45px;
  text-align: center;
  font-weight: 400;
}

.posibilities-swiper-button-next,
.posibilities-swiper-button-prev {
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 10;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.posibilities-swiper-button-next {
  right: 10px;
}

.posibilities-swiper-button-prev {
  transform: rotate(180deg);

  left: 10px;
}

.posibilities-swiper-button-next img,
.posibilities-swiper-button-prev img {
  width: 100%;
  height: 100%;
}



@media (max-width: 1400px) {
  .posibilities-swiper-container {
    width: 80%;
  }
}

@media (max-width: 900px) {
  .posibilities-swiper-container {
    width: 90%;
    margin: 80px auto;
  }

  .posibilities-card-title {
    font-size: 28px;
    margin-bottom: 35px;
  }

  .posibilities-card-img {
    width: 75%;
  }
}

@media (max-width: 600px) {
  .posibilities-swiper-container {
    width: 100%;
    padding: 0px 20px;
    margin: 60px auto;
  }

  .posibilities-card-title {
    font-size: 28px;
    margin-bottom: 35px;
  }

  .posibilities-card-img {
    width: 100%;
  }

  .posibilities-swiper-button-next,
  .posibilities-swiper-button-prev {
    display: none;
  }
}

@media (max-width: 480px) {
  .posibilities-swiper-container {
    margin: 40px auto;
  }

  .posibilities-card-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
