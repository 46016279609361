.container {
  width: 100%;
  background-image: url(../../../shared/assets/images/typing-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  padding: 0px 40px;
  padding-bottom: 100px !important;
}

.wrapper {
  max-width: 1440px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.system-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 70px;
  border-radius: 10px;
  background-color: var(--card-color);
  width: fit-content;
  margin-bottom: 50px;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.language-flex {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.system-item-flex {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}

.system-text {
  font-size: 25px;
  color: #ccc9d0;
}

.active {
  color: var(--primary-color);
}

.dropdown-option {
  font-size: 18px !important;
}

.line {
  background-color: #ccc9d0;
  height: 100%;
  width: 5px;
  border-radius: 10px;
  margin: 0px 50px;
}

.language-text {
  color: #ccc9d0;
  font-size: 40px;
  margin-left: 20px;
}

.hide {
  opacity: 0;
  transform: translateY(-20px);
  pointer-events: none;
}

@media (max-width: 900px) {
  .container {
    padding: 0px 30px;
  }

  .system-box {
    padding: 10x 40px;
    margin-bottom: 30px;
  }

  .system-text {
    font-size: 20px;
    text-align: center;
  }

  .language-text {
    font-size: 30px;
  }

  .line {
    margin: 0px 35px;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 0px 20px;
  }

  .system-box {
    padding: 8px 20px;
  }

  .system-text {
    font-size: 16px;
    text-align: center;
  }

  .language-text {
    font-size: 25px;
    margin-left: 10px;
  }

  .language-flex img {
    width: 28px;
    height: 28px;
  }

  .line {
    margin: 0px 25px;
  }
}
