/* LoginLanguageChange.module.css */
.language-container {
  display: flex;
  justify-content: center;
}

.language-option {
  cursor: pointer;
  font-size: 18px;
  color: var(--white-color);
}

.active-language {
  color: var(--primary-color);
}

.slash {
  margin: 0px 20px;
  font-size: 18px;
  color: var(--white-color);
}
