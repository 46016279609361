.container {
  display: flex;
  align-items: center;
  gap: 50px;
  margin: auto;
  max-width: 1440px;
}

.img {
  height: 736px;
  width: 736px;
}

.right-box {
  margin-top: 100px;
}

.small-img {
  cursor: pointer;
}

.header {
  font-size: 70px;
  line-height: 90px;
}

.name {
  font-size: 75px;
  text-align: center;
  margin-bottom: 40px;
  margin-top: -20px;
}

.flex {
  display: flex;
  align-items: center;
  gap: 20px;
}

.thumb-img-box {
  width: 70px;
  height: 70px;
  border: 5px solid #bbbbbb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s all;
}

.thumb-img-box:hover {
  transform: scale(1.1);
}

.thumb-img {
  width: 100%;
  height: 100%;
  margin-bottom: -10px;
}

.details {
  width: 140px;
  height: 70vh;
  background: linear-gradient(
    107.49deg,
    rgba(106, 0, 252, 0.01) 1.24%,
    #6a00fc 66.94%
  );
  position: absolute;
  top: -250px;
  left: 35%;
  transform: rotate(15deg);
  z-index: -1;
}

.details-outlined {
  width: 90px;
  height: 100vh;
  background: none;
  position: absolute;
  top: -100px;
  left: 180px;
  transform: rotate(-15deg);
  z-index: -1;
  border: none;
}

.details-outlined::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 3px solid;
  border-image: linear-gradient(
      107.49deg,
      rgba(106, 0, 252, 0.01) 1.24%,
      #6a00fc 66.94%
    )
    1;
  transform: rotate(-15deg);
  box-sizing: border-box;
}

@keyframes moveShadow {
  0% {
    left: 0;
  }
  100% {
    left: calc(100% - 600px);
  }
}

.shadow {
  width: 400px;
  height: 400px;
  position: absolute;
  left: 0; /* Start from the left */
  top: 30%;
  background: linear-gradient(
    107.49deg,
    rgba(106, 0, 252, 0.01) 1.24%,
    #6a00fc 66.94%
  );
  border-radius: 50%;
  filter: blur(150px);
  opacity: 0.7;
  z-index: -1;
  animation: moveShadow 10s infinite alternate; /* Adjust the duration as needed */
}

.particle {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: -2;
  transform: scale(1);
}

@keyframes cursorMove {
  0%,
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
  }
}

.custom-cursor {
  position: fixed;
  top: 0;
  left: 0;
  width: 45px;
  height: 45px;
  background: radial-gradient(circle at 50% 50%, #ffd700, #ffcc00);
  border: 2px solid #ffa500;
  border-radius: 50%;
  pointer-events: none;
  z-index: 10000;
  transform: translate(-50%, -50%);
  animation: cursorMove 0.6s infinite;
}

.custom-cursor::before {
  content: 'C';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(circle at 50% 50%, #fff5a1, #ffd700);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px rgba(255, 202, 29, 0.5), 0 0 20px rgba(255, 202, 29, 0.3);
}

.custom-cursor-active {
  background: radial-gradient(circle at 50% 50%, #ffa500, #ff8c00);
}

@media (max-width: 1700px) {
  .img {
    width: 620px;
    height: 620px;
  }
}

@media (max-width: 1600px) {
  .img {
    width: 600px;
    height: 600px;
  }

  .container {
    justify-content: space-around;
    gap: 0px;
  }

  .details {
    height: 500px;
  }

  .details-outlined {
    left: 140px;
  }
}

@media (max-width: 1400px) {
  .container {
    margin-bottom: 100px;
    justify-content: space-evenly;
  }
  .img {
    width: 500px;
    height: 500px;
  }

  .details {
    left: 30%;
    height: 700px;
    width: 90px;
  }

  .details-outlined {
    left: 5%;
    width: 80px;
    height: 700px;
  }

  .header {
    font-size: 60px;
  }

  .name {
    font-size: 65px;
    margin-bottom: 30px;
  }
}

@media (max-width: 1020px) {
  .container {
    flex-direction: column;
    height: 100%;
    margin-bottom: 50px;
    justify-content: start;
    gap: 0px;
  }
  .particle {
    width: 80%;
  }
  .img {
    width: 450px;
    height: 450px;
  }

  .custom-cursor {
    top: -100px !important;
    left: -100px !important;
    display: none !important;
  }

  .custom-cursor::before {
    display: none !important;
  }

  .custom-cursor-active {
    display: none !important;
  }

  .right-box {
    margin-top: 0px;
  }

  .header {
    text-align: center;
  }
  .name {
    text-align: center;
  }
  .details {
    display: none;
  }

  .details-outlined {
    display: none;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 0px 20px;
  }
  .right-box {
    margin-top: 10px;
  }
  .img {
    width: 100%;
    height: 100%;
  }
  .header {
    font-size: 50px;
    line-height: 65px;
  }
  .name {
    font-size: 55px;
  }
  .flex {
    display: flex;
    padding: 0px 10px;
    transform: scale(0.8);
    margin-top: 20px;
  }
  .shadow {
    width: 100%;
    left: 0px;
  }
}

@media (max-width: 480px) {
  .container {
    margin-bottom: 20px;
  }
  .header {
    font-size: 40px;
    line-height: 50px;
  }
  .name {
    font-size: 45px;
    margin-bottom: 0px;
  }

  .right-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .thumb-img-box {
    width: 50px;
    height: 50px;
  }

  .flex {
    display: flex;
    padding: 0px;
    gap: 10px;
    transform: scale(1);
  }
}
