.container {
  margin-top: 120px;
  background: #0a0a11;
  width: 100%;
}

.wrapper {
  padding: 55px 40px 60px 40px;
  max-width: 1440px;
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.logo {
  font-size: 40px;
}

.social-media-title {
  font-size: 18px;
  color: var(--white-color);
  margin-bottom: 10px;
  margin-top: 10px;
}

.social-media-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.circle {
  width: 32px;
  height: 32px;
  border: 1px solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s all;
}

.circle:hover {
  background: var(--primary-color);
  transform: scale(1.2);
}

/* link boxes */
.box {
  display: flex;
  flex-direction: column;
}

.box-category-title {
  font-size: 26px;
  margin-bottom: 10px;
}

.item-link {
  font-size: 18px;
  margin: 5px 0px;
  color: var(--white-color);
  text-decoration: none;
}

.item-link:hover {
  background: linear-gradient(96.52deg, #bac5ff 9.98%, #a05fff 93.94%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact-text {
  font-size: 26px;
  color: var(--white-color);
}

.input-box {
  display: flex;
  align-items: center;
  background: var(--card-color);
  margin-top: 35px;
  height: 75px;
  border-radius: 0px 20px 0px 20px;
}

.input {
  outline: none;
  border: none;
  background: none;
  height: 100%;
  padding: 18px 23px;

  /* text */
  font-size: 26px;
  color: var(--white-color);
}

.icon-box {
  background-color: var(--primary-color);
  width: 60px;
  height: 100%;
  border-top-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: var(--white-color);
}

.icon {
  color: white !important;
}

@media (max-width: 1350px) {
  .wrapper {
    justify-content: space-evenly;
  }
  .logo {
    font-size: 35px;
  }
  .social-media-title {
    font-size: 17px;
  }
  .social-media-flex {
    gap: 8px;
  }

  .box-category-title {
    font-size: 24px;
    margin-bottom: 8px;
  }

  .item-link {
    font-size: 16px;
  }

  .contact-text {
    font-size: 24px;
  }
  .input-box {
    height: 65px;
  }

  .last-column {
    display: none;
  }
}

@media (max-width: 920px) {
  .wrapper {
    padding: 45px 30px 50px 30px;
    display: grid;
    grid-template-columns: 250px 250px;
    justify-content: center;
    gap: 40px;
  }

  .social-media-title {
    margin-top: 20px;
  }
}

@media (max-width: 600px) {
  .container {
    margin-top: 80px;
    display: none;
  }
  .wrapper {
    padding: 35px 20px 40px 20px;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .container {
    margin-top: 60px;
  }
  .wrapper {
    grid-template-columns: 1fr;
    gap: 30px;
  }
  .logo {
    font-size: 40px;
  }
}
