.language_change_button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  margin-right: 10px;
}

.language_name {
  margin-right: 8px;
  font-weight: bold;
}

.language_icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.arrow_icon {
  font-size: 20px !important;
  color: var(--white-color) !important;
}

.language_menu {
  border-radius: 12px;
  box-shadow: 2px 2px 50px 0px rgba(0, 0, 0, 0.15);
  margin-top: 8px;
}

.language_item {
  display: flex !important;
  align-items: center !important;
  transition: background-color 0.2s ease-in-out;
}

.language_item:hover {
  background-color: none !important;
}

.language_icon {
  width: 30px;
  height: 30px;
  margin-right: 5px !important;
}

.language_name {
  font-size: 18px !important;
  display: flex;
  align-items: center;
  font-weight: 400;
  margin-left: 8px !important;
}
