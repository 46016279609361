/* Full screen cover color behind the modal */
.ant-modal-mask {
  background-color: #101017 !important;
}

.modal-title {
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ant-modal-content,
.ant-picker-calendar,
.ant-picker-body {
  background-color: #242628 !important;
}

.ant-modal-header,
.ant-modal-close {
  display: none;
}

.ant-picker-calendar-header {
  margin-bottom: 20px !important;
}

.custom-calendar .ant-picker-cell {
  background-color: #242628;
  border: 1px solid #ffffff;
}

.custom-calendar .ant-picker-calendar {
  background-color: #242628;
}

.custom-date-cell {
  color: #ffffff;
  text-align: center;
  font-size: 40px;
  padding: 17px 15px 0px 0px;
  line-height: 45px;
  display: flex;
  justify-content: flex-end;
}

.custom-date-cell.attended {
  background-color: #00ff00; /* Nice green color */
}

.custom-date-cell.absent {
  background-color: #ff0000; /* Nice red color */
}

.custom-date-cell.empty-cell {
  background-color: #242628; /* Hide empty cells */
  visibility: hidden; /* Or use `display: none` if you prefer */
}

.custom-calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #242628;
  color: #ffffff;
  margin-bottom: 40px;
}

.calendar-title {
  margin: 0;
  font-size: 40px;
}

.calendar-controls {
  display: flex;
  align-items: center;
}

.current-month {
  margin: 0 10px;
  font-size: 35px;
  margin: 0px 20px;
  text-transform: uppercase;
  user-select: none;
}

/* .ant-radio-group {
  display: none;
} */

.ant-picker-calendar-year-select {
  display: none;
}

.ant-picker-calendar-date-value {
  font-size: 30px !important;
  color: var(--white-color) !important;
}

.ant-picker-calendar-date-content {
  display: none;
}

.ant-picker-body th {
  color: var(--white-color) !important;
  font-size: 20px;
}

.ant-picker-cell-inner {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 20px !important;
  margin: auto !important;
}

.ant-picker-calendar-date .ant-select-selector {
  background: none !important;
}

.ant-picker-calendar-date-today {
  background-color: var(--primary-color) !important;
  border: none !important;
}

.arrow-box {
  background-color: #f6f6f6;
  width: 50px;
  height: 50px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.right-arrow-icon {
  transform: rotate(180deg);
  user-select: none;
}
