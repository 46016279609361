.notification-bubble {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 55px;
  height: 55px;
  background-color: var(--primary-color);
  color: var(--white-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: beat 1.5s infinite;
  z-index: 999999;
}

.icon-container {
  position: relative;
  margin-top: 10px;
}

.notification-count {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  background-color: var(--white-color);
  color: var(--primary-color);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.icon {
  font-size: 20px;
  color: var(--white-color);
  margin-left: -5px;
}

@keyframes beat {
  0% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  20% {
    transform: scale(1.1);
    box-shadow: 0 0 20px rgba(141, 102, 255, 0.5); /* Stronger glow */
  }
  40% {
    transform: scale(1.2);
    box-shadow: 0 0 30px rgba(141, 102, 255, 0.6); /* Strongest glow */
  }
  60% {
    transform: scale(1.1);
    box-shadow: 0 0 20px rgba(141, 102, 255, 0.5); /* Returning to slightly smaller glow */
  }
  80% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}

.notification-bubble:active {
  transform: scale(0.9);
}
