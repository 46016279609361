.even-row {
  background-color: #242628;
}

.odd-row {
  background-color: #2c2e31;
}

.ant-table-wrapper {
  margin-top: 50px !important;
}

.ant-table-wrapper .ant-table {
  background: none !important;
}

.ant-table-cell {
  border-bottom: none !important;
  color: var(--white-color) !important;
  font-size: 18px !important;
  background: none !important;
}

.ant-table-cell-row-hover {
  background: none !important;
  cursor: pointer;
}

.ant-table-row {
  border-radius: 8px !important;
}

.ant-table-cell::before {
  display: none !important;
}

.ant-table-thead tr .ant-table-cell {
  color: #d1d0c5;
  font-weight: 400 !important;
  font-size: 25px;
  padding-bottom: 30px;
}

@media (max-width: 600px) {
  .ant-table-wrapper {
    margin-top: 30px !important;
  }
  .ant-table-cell {
    font-size: 16px !important;
  }
}
