.card {
  border-radius: 20px;
  padding-bottom: 30px;
  background-color: var(--bg-color);
}

.shadow-right {
  box-shadow: 5px 5px 3px 0px #6a01fc;
}

.shadow-left {
  box-shadow: -5px 5px 5px 0px #6a01fc;
}

.img {
  width: 545px;
  height: 545px;
  border-radius: 20px;
}

.name {
  text-align: center;
  margin: 35px 0px 15px 0px;
  font-size: 30px;
  color: var(--white-color);
}

.position {
  font-size: 27px;
  text-align: center;
  /* margin-bottom: 30px; */
}

.full-width {
  width: 100%;
  display: flex;
  justify-content: center;
}

.full-width .card {
  width: 545px;
}

@media (max-width: 1300px) {
  .img {
    width: 480px;
    height: 480px;
  }

  .name {
    font-size: 25px;
    margin: 25px 0px 10px 0px;
  }

  .position {
    font-size: 22px;
  }

  .full-width .card {
    width: 480px;
  }
}

@media (max-width: 1150px) {
  .img {
    width: 380px;
    height: 380px;
  }

  .name {
    font-size: 25px;
    margin: 25px 0px 10px 0px;
  }

  .position {
    font-size: 22px;
  }

  .full-width .card {
    width: 380px;
  }
}

@media (max-width: 900px) {
  .shadow-left {
    box-shadow: 5px 5px 5px 0px #6a01fc;
  }
}

@media (max-width: 600px) {
  .card {
    padding-bottom: 18px;
  }
  .img {
    width: 300px;
    height: 300px;
  }

  .full-width .card {
    width: 300px;
  }

  .name {
    font-size: 20px;
    margin: 18px 0px 8px 0px;
  }

  .position {
    font-size: 17px;
  }
}

@media (max-width: 420px) {
  .img {
    width: 280px;
    height: 280px;
  }

  .name {
    padding: 0px 10px;
  }

  .position {
    text-wrap: wrap;
  }

  .full-width .card {
    width: 280px;
  }
}
