.back-btn {
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 400px;
  background: var(--primary-color);
  cursor: pointer;
  padding: 8px 15px;
  border-radius: 10px;
  color: var(--white-color);
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.back-btn:active {
  transform: scale(0.96);
}

.arrow-icon {
  width: 16px;
  height: 16px;
}

.title {
  font-size: 24px;
  color: var(--white-color);
  margin-bottom: 25px;
}

.box {
  background: var(--card-color);
  border-radius: 10px;
  padding: 25px 20px;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.answer {
  font-size: 20px;
  line-height: 35px;
}

.btn {
  border: none;
  outline: none;
  font-size: 24px;
  font-weight: 400px;
  background: var(--primary-color);
  cursor: pointer;
  width: 250px;
  padding: 8px 0px;
  border-radius: 40px;
  color: var(--white-color);
  margin: 20px 0px;
}

.btn:active {
  transform: scale(0.96);
}

@media (max-width: 600px) {
  .box {
    width: calc(100vw - 60px);
    padding: 15px 12px;
  }

  .title {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .btn {
    font-size: 20px;
    width: auto;
    padding: 8px 35px;
    margin-bottom: 0px;
  }
}

@media (max-width: 360px) {
  .box {
    width: calc(100vw - 60px);
    padding: 15px 12px;
  }
}
