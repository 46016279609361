.container {
  margin: 0px auto;
  padding: 80px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  background-image: url(../../shared/assets/images/quiz-result.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.header {
  font-size: 40px;
  margin-bottom: 50px;
}

.box {
  background: var(--card-color);
  padding: 35px 50px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 5px 5px 5px 0px #6a01fc;
}

.result-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 50px;
}

.result-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.result-type {
  font-size: 20px;
  color: var(--white-color);
  margin-bottom: 30px;
  font-weight: 600;
}

.result-text {
  font-size: 25px;
  font-weight: 600;
}

.link-box {
  width: 350px;
  background-color: #333333;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 70px 0px 40px 0px;
  border-radius: 10px;
  display: none;
}

.back-icon {
  color: var(--white-color);
  font-size: 35px;
  margin: 30px;
  font-weight: 900;
  cursor: pointer;
  transition: 0.4s all;
}

.back-icon:hover {
  transform: scale(1.2);
}

@media (max-width: 900px) {
  .container {
    padding: 60px 30px;
  }

  .header {
    font-size: 30px;
  }

  .box {
    padding: 30px 40px;
  }

  .result-wrap {
    gap: 30px;
  }

  .result-type {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
  }

  .result-text {
    font-size: 22px;
  }
}

@media (max-width: 720px) {
  .result-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 40px 20px;
  }

  .header {
    font-size: 25px;
    margin-bottom: 20px;
  }
  .box {
    box-shadow: 0px 0px 5px 5px #6a01fc;
  }
}

@media (max-width: 480px) {
  .result-wrap {
    grid-template-columns: 1fr;
  }
}
