@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes gradientMove {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@keyframes popUp {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(0.7);
  }
}

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-color);
  position: relative;
}

.logo {
  transform: scale(0.6);
  animation: pulse 1s infinite;
  width: 400px;
  height: 400px;
  position: absolute;
  z-index: 2;
}

.text-box {
  width: fit-content;
  margin: auto;
  text-align: center;
}

.welcome-text {
  font-size: 60px;
  font-weight: bold;
  animation: fadeIn 2s ease-in-out, gradientMove 15s infinite linear,
    popUp 0.6s ease-out;
  background-size: 400% 100%;
}

.name {
  font-size: 90px;
  font-weight: bold;
  animation: fadeIn 2s ease-in-out, gradientMove 15s infinite linear,
    popUp 0.6s ease-out;
  background-size: 400% 100%;
}

.shadow {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 400px;
  background: linear-gradient(
    107.49deg,
    rgba(106, 0, 252, 0.01) 1.24%,
    #6a00fc 66.94%
  );
  border-radius: 50%;
  filter: blur(150px);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px) {
  .container {
    padding: 0px 20px;
  }
  .logo {
    width: 400px;
    height: 400px;
    transform: scale(1);
  }
  .welcome-text {
    font-size: 45px;
  }
  .name {
    font-size: 50px;
  }

  .shadow {
    width: 80%;
    height: 300px;
  }
}
