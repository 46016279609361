.loader-container {
  margin-top: 50px;
}

.container {
  margin-top: 50px;
}

.header {
  color: var(--white-color);
}

.text {
  color: var(--white-color);
  margin-bottom: 40px;
  font-size: 25px;
}

.history-wrap {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  width: 100%;
}

.card-wrapper {
  box-sizing: border-box;
}

.card {
  display: flex;
  align-items: center;
  background-color: var(--card-color);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0px 20px 0px 10px;
}

.img {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.info {
  padding: 16px;
}

.status-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 14px;
  color: var(--white-color);
}

.no-purchase-text {
  color: var(--white-color);
  font-size: 20px;
}

.status-yellow {
  background-color: var(--red-color);
}

.status-green {
  background-color: var(--green-color);
}

.name {
  font-size: 24px;
  margin-bottom: 8px;
  color: var(--white-color);
}

.price {
  font-size: 18px;
  margin-bottom: 4px;
  color: var(--white-color);
}

.bold {
  font-weight: 600;
}

.date {
  text-wrap: nowrap;
  font-size: 18px;
  margin-bottom: 4px;
  color: var(--white-color);
  font-weight: 600;
}

@media (max-width: 1200px) and (min-width: 600px) {
  .history-wrap {
    gap: 15px;
  }

  .card {
    padding-right: 10px;
  }

  .img {
    width: 120px;
    height: 120px;
  }

  .info {
    padding: 12px;
  }
  .name {
    font-size: 20px;
  }

  .price,
  .date {
    font-size: 17px;
    text-wrap: wrap;
  }
}

@media (max-width: 600px) {
  .history-wrap {
    grid-template-columns: 400px;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .container {
    margin-top: 30px;
  }
  .history-wrap {
    grid-template-columns: 1fr;
    justify-content: center;
  }

  .card {
    padding-right: 10px;
  }

  .img {
    width: 120px;
    height: 120px;
  }

  .info {
    padding: 12px;
  }
  .name {
    font-size: 20px;
  }

  .price,
  .date {
    font-size: 17px;
    text-wrap: wrap;
  }
}
