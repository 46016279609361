.main-container {
  width: 100%;
  padding: 0px 40px;
  margin: 100px auto;
  max-width: 1440px;
}

.container {
  background: var(--card-color);
  width: 100%;
  padding: 25px;
  border-radius: 20px;
  margin-top: 40px;
}

.header-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 40px;
  color: #d1d0c5;
  margin-right: 30px;
}

.btn-flex {
  display: flex;
  align-items: center;
  gap: 20px;
}

.btn {
  width: 175px;
  height: 60px;
  border-radius: 10px;
  background: var(--white-color);
  color: var(--card-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  border: none;
  font-size: 20px;
  user-select: none;
  transition: background 0.3s, color 0.3s;
}

.btn:active {
  transform: scale(0.95);
}

.btn.active {
  background: var(--primary-color);
  color: var(--white-color);
}

.descr {
  font-size: 20px;
  margin-top: 10px;
  color: #646669;
}

.btn-flex-wrapper {
  width: fit-content;
}

.go-back-btn {
  width: auto;
  padding: 8px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background: var(--primary-color);
  color: var(--white-color);
}

.loader-container {
  margin-top: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1200px) {
  .title {
    font-size: 30px;
  }

  .btn {
    width: fit-content;
    padding: 0px 20px;
    text-wrap: nowrap;
    height: 50px;
    font-size: 18px;
  }

  .descr {
    font-size: 18px;
  }
}

@media (max-width: 900px) {
  .main-container {
    padding: 0px 30px;
  }

  .header-box {
    flex-direction: column;
    gap: 20px;
  }

  .title {
    text-align: center;
    margin-right: 0px;
  }

  .double-btn {
    width: 180px;
  }

  .descr {
    display: none;
  }
}

@media (max-width: 780px) {
  .table-container {
    overflow-x: auto;
    padding-bottom: 10px;
  }
}

@media (max-width: 600px) {
  .main-container {
    padding: 0px 20px;
    margin: 60px auto;
  }

  .container {
    padding: 30px 15px;
    margin-top: 20px;
  }

  .double-btn {
    padding: 6px 25px;
    font-size: 16px;
    width: 160px;
  }

  .title {
    font-size: 24px;
  }
}

@media (max-width: 420px) {
  .double-btn {
    width: fit-content;
    padding: 7px 20px;
    height: auto;
  }
}
