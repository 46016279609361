.course-category-container::-webkit-scrollbar {
  width: 12px;
  height: 5px;
}

.course-category-container::-webkit-scrollbar-track {
  background: var(--bg-color);
}

.course-category-container::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 8px;
}

.course-category-container::-webkit-scrollbar-thumb:hover {
  background: #7a5aff;
}

.course-category-container {
  width: fit-content;
  overflow-x: auto;
  padding: 12px 0px;
  margin-bottom: 80px;
}

.ant-segmented {
  background: none;
}

.ant-segmented-group {
  gap: 20px;
}

.ant-segmented-item-label {
  background: #14141a;
  color: var(--white-color);
  padding: 6px 20px !important;
  border-radius: 90px;
  font-size: 20px;
}

.ant-segmented-thumb {
  background-color: var(--primary-color) !important;
  border-radius: 90px;
}

.ant-segmented-item::after {
  border-radius: 90px;
}

.ant-segmented-item-selected {
  background: var(--primary-color) !important;
  border-radius: 90px !important;
  color: var(--white-color) !important;
}

.ant-segmented-item-selected .ant-segmented-item-label {
  color: var(--white-color);
  background: var(--primary-color) !important;
}

.ant-segmented-item-input {
  background-color: var(--primary-color);
}

@media (max-width: 1200px) {
  .course-category-container {
    margin-bottom: 40px;
  }
}

@media (max-width: 1020px) {
  .course-category-container {
    width: 100%;
  }
}
