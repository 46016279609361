@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
}

:root {
  /* colors */
  --primary-color: #8d66ff;
  --bg-color: #101017;
  --card-color: #242628;
  --red-color: #d42620;
  --green-color: #4caf50;
  --white-color: #ffffff;
  --black-color: #000000;
}

.gradient-txt {
  background: linear-gradient(96.52deg, #bac5ff 9.98%, #a05fff 93.94%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

body {
  background: var(--bg-color);
}

/* NavLink */
.active {
  background: linear-gradient(96.52deg, #bac5ff 9.98%, #a05fff 93.94%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Selection Placeholder */
:where(
    .css-dev-only-do-not-override-98ntnt
  ).ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  color: #4d4d4d !important;
}

.ec-error {
  font-size: 25px;
  color: var(--red-color);
  text-align: center;
}

/* Antd Message */
.ant-message {
  z-index: 9999999999999999 !important;
}

.lazy-load-image-background {
  align-items: center;
}

.table-coin-box-flex {
  display: flex;
  align-items: center;
}

.table-coin-box-flex img {
  width: 40px;
  height: 40px;
  margin-right: 6px;
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
}

@media (max-width: 600px) {
  .ant-modal-content {
    margin: 0px 20px !important;
  }
}

@media (max-width: 480px) {
  .ant-modal-content {
    margin: 0px 10px !important;
  }
}

.typing-avatar-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.ant-dropdown-menu {
  background-color: #13131b !important;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.ant-dropdown-menu-item {
  color: #bbbbbb !important;
}

.ant-dropdown-menu-item:hover {
  background-color: #1e1e2d !important;
}

.ant-dropdown-menu-title-content {
  display: flex !important;
  align-items: center !important;
}

.hamburger-react {
  color: white !important;
  display: none;
}

@media (max-width: 1250px) {
  .hamburger-react {
    display: block;
  }
}

.table-container::-webkit-scrollbar {
  width: 12px;
  height: 5px;
}

.table-container::-webkit-scrollbar-track {
  background: var(--bg-color);
}

.table-container::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 8px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: #7a5aff;
}
