.container {
  max-width: 1440px;
  width: 100%;
  margin: 60px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 40px;
}

.header {
  font-size: 45px;
  text-align: center;
  margin-bottom: 80px;
}

.img {
  width: 90%;
  height: 556px;
}

@media (max-width: 1020px) {
  .container {
    padding: 0px 30px;
  }

  .header {
    font-size: 35px;
    margin-bottom: 60px;
  }
  .img {
    height: 420px;
  }
}

@media (max-width: 780px) {
  .img {
    height: 340px;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 0px 20px;
  }

  .header {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 40px;
  }

  .img {
    height: 280px;
  }
}

@media (max-width: 420px) {
  .img {
    height: 250px;
  }
}
