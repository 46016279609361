.text-flex {
  display: flex;
  align-items: center;
  gap: 50px;
  margin-bottom: 35px;
}

.text-flex h1 {
  color: #9997a5;
  font-size: 20px;
  cursor: pointer;
  transition: 0.3s all;
}

.text-flex h1:hover {
  color: var(--white-color);
}

@media (max-width: 600px) {
  .text-flex {
    gap: 30px;
  }

  .text-flex h1 {
    text-align: center;
    font-size: 18px;
  }
}
