.particle-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

#tsparticles canvas {
  position: absolute !important;
}
