.container {
  width: 100%;
  position: relative;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url(../../../shared/assets/images/about.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: -1;
}

.wrapper {
  max-width: 1400px;
  width: 100%;
  padding: 0px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.title {
  font-size: 40px;
  color: var(--white-color);
  text-align: center;
  padding-top: 160px;
}

.descr {
  font-size: 30px;
  color: #bababa;
  margin: 10px 0px 40px 0px;
  text-align: center;
}

.btn {
  border: none;
  outline: none;
  background: var(--primary-color);
  color: var(--white-color);
  font-size: 20px;
  padding: 10px 60px;
  border-radius: 10px;
  cursor: pointer;
}

.btn:active {
  transform: scale(0.96);
}

.team-header {
  font-size: 40px;
  text-align: center;
  padding-top: 130px;
  padding-bottom: 90px;
}

.team-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 120px 80px;
  width: 100%;
}

@media (max-width: 1150px) {
  .team-wrap {
    justify-content: space-evenly;
    gap: 80px 40px;
  }
}

@media (max-width: 900px) {
  .wrapper {
    padding: 0px 30px;
  }
  .title {
    font-size: 35px;
    padding-top: 140px;
  }

  .descr {
    font-size: 25px;
  }

  .btn {
    font-size: 18px;
    padding: 10px 50px;
  }

  .team-header {
    font-size: 35px;
  }
}

@media (max-width: 600px) {
  .wrapper {
    padding: 0px 20px;
  }

  .title {
    font-size: 30px;
    padding-top: 120px;
  }

  .descr {
    font-size: 20px;
  }

  .btn {
    font-size: 18px;
    padding: 10px 40px;
  }

  .team-header {
    font-size: 30px;
    padding-bottom: 60px;
    padding-top: 100px;
  }

  .team-wrap {
    gap: 40px;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 25px;
    padding-top: 80px;
  }

  .descr {
    font-size: 18px;
  }

  .team-header {
    font-size: 25px;
    padding-bottom: 50px;
    padding-top: 80px;
  }
}
