.container {
  padding: 15px 60px 0px 15px;
  background-color: var(--bg-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px auto;
  width: 100%;
}

.left-box {
  display: flex;
  align-items: center;
  gap: 50px;
}

.flex {
  display: flex;
  align-items: center;
  gap: 35px;
}

.flex-icon {
  cursor: pointer;
}

.user-icon {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.default-user-icon {
  cursor: pointer;
}

.menu-item {
  font-size: 16px !important;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 5px;
  font-size: 20px;
}

.profile-img-wrapper {
  position: relative;
  width: 45px;
  height: 45px;
  border-radius: 8px 25px 8px 8px;
  cursor: pointer;
}

.profile-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px 0px 8px 8px;
  cursor: pointer;
  clip-path: polygon(0 0, calc(100% - 18px) 0, 100% 18px, 100% 100%, 0 100%);
}

.profile-arrow {
  position: absolute;
  top: -15px;
  right: -10px;
  font-size: 25px;
  font-weight: bold;
  color: var(--white-color);
  transform: rotate(-45deg);
}

.right-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (max-width: 900px) {
  .container {
    padding: 12px 30px 0px 12px;
  }

  .logo {
    width: 100%;
  }

  .left-box {
    gap: 30px;
  }

  .left-box .link {
    width: 250px;
    margin-left: -20px;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 10px 20px 10px 8px;
  }

  .logo {
    width: 100%;
  }

  .left-box {
    gap: 20px;
  }

  .language-box {
    display: none;
  }

  .left-box .link {
    width: 180px;
    margin-left: -20px;
  }

  .flex {
    gap: 16px;
  }

  .flex-icon {
    width: 25px;
  }

  .profile-img-wrapper {
    transform: scale(0.8);
  }
}
