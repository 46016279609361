.main-container {
  position: relative;
  overflow: hidden;
}

.container {
  max-width: 1440px;
  margin: 80px auto;
  display: flex;
  gap: 60px;
  padding: 0px 40px;
}

.right-box {
  width: fit-content;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.circle-container {
  position: absolute;
  right: -25%;
  top: 80px;
  height: 90vh;
  width: 90vh;
  overflow: hidden;
  border-radius: 50%;
  z-index: -1;
  background: #1e1e1e;
}

.shadow {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  height: 80%;
  width: 80%;
  background: linear-gradient(
    107.49deg,
    rgba(106, 0, 252, 0.01) 1.24%,
    #6a00fc 66.94%
  );
  border-radius: 50%;
  filter: blur(80px);
  opacity: 0.7;
}

@media (max-width: 1600px) {
  .circle-container {
    right: -22%;
    top: 120px;
    height: 80vh;
    width: 80vh;
  }
}

@media (max-width: 1020px) {
  .container {
    flex-direction: column;
    justify-content: center;
    margin: 60px auto;
    gap: 40px;
    padding: 0px 30px;
  }

  .circle-container {
    display: none;
  }
}

@media (max-width: 600px) {
  .container {
    gap: 20px;
    padding: 0px 20px;
  }

  .right-box {
    align-items: center;
    margin: 40px auto;
  }
}
