.testContainer {
  width: 900px;
  position: relative;
  margin: 0 auto;
}

.timer {
  width: 100%;
  height: 3px;
  background-color: #55b1fc;
  top: 0;
  left: 0;
  transition: 0.2s;
}

.output {
  font-size: 25px;
  line-height: 35px;
  position: relative;
  font-weight: 400;
  color: #646669;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  margin-top: 30px;
  background-color: var(--card-color);
  padding: 35px 45px !important;
  border-radius: 30px;
  user-select: none;
}

.word {
  margin-bottom: 5px;
  margin-right: 5px;
}

.word,
.wordLetter {
  position: relative;
  transition: 0.2s;
}

.wordLetterAnim::before {
  position: absolute;
  content: '';
  background-color: rgba(85, 177, 252, 0.2);
  width: 100%;
  height: 0;
  left: 0;
  bottom: 0;
  animation: grow 0.2s 1;
}

@keyframes grow {
  0% {
  }
  100% {
    height: 100%;
  }
}

.wordInput {
  display: flex;
  color: #202326;
  border: 2px solid purple;
  margin-top: 30px;
  border-radius: 30px;
}

.refresh {
  outline: none;
  border: none;
  background-color: var(--primary-color);
  font-size: 25px;
  color: var(--card-color);
  padding: 5px;
  cursor: pointer;
  flex: 1;
  transition: 0.2s;
  border-radius: 0px 30px 30px 0px;
  color: white;
}

.wordLine {
  padding-bottom: 8px;
  width: calc(100% - 12px);
  margin: 0 auto;
}

.typeWord {
  flex: 15;
  width: calc(100% - 70px);
  border: none;
  padding: 5px;
  outline: none;
  background-color: var(--card-color);
  font-size: 20px;
  font-weight: bold;
  color: white;
  border-radius: 30px 0px 0px 30px;
  padding: 8px 20px;
}

.countDown {
  text-align: center;
  font-size: 60px;
  color: var(--white-color);
  margin-top: 35px;
}

.result {
  color: var(--white-color);
}

.currWord {
  background-color: rgba(85, 177, 252, 0.2);
}

.correctWord {
  color: rgb(255, 255, 255);
}

.incorrectWord {
  color: var(--primary-color);
}

@media (max-width: 1020px) {
  .testContainer {
    width: 80%;
  }
}

@media (max-width: 900px) {
  .testContainer {
    width: 90%;
  }
  .output {
    font-size: 21px;
    line-height: 30px;
    padding: 30px !important;
  }

  .countDown {
    font-size: 45px;
    margin-top: 15px;
  }
}

@media (max-width: 600px) {
  .testContainer {
    width: 100%;
  }
  .output {
    font-size: 18px;
    line-height: 26px;
    padding: 25px !important;
  }

  .countDown {
    font-size: 35px;
    margin-top: 15px;
  }

  .typeWord {
    font-size: 18px;
  }

  .refresh {
    padding: 5px 10px;
    font-size: 20px;
  }
}

@media (max-width: 360px) {
  .output {
    padding: 15px !important;
  }
  .wordInput {
    margin-top: 15px;
  }
}
